import React, { useState, useEffect } from "react";

import {
  FileOutlined,
  DeleteOutlined,
  LockFilled,
  EyeOutlined,
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import {
  Col,
  Row,
  Button,
  Card,
  Drawer,
  theme,
  Result,
  Typography,
  Space,
  notification,
  Popconfirm,
  Segmented
} from "antd";

import { Loader } from "../../components"
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";
import CSVViewer from "./CSVViewer";
import { Upload } from "../../components/upload/Upload";
import { functions } from "../../firebase_setup/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { SrtViewer } from "../../components";
import { FormatIcon } from "../../components";

const { useToken } = theme;

export const AssetsPanel = ({ 
        researchAssets, 
        researchId, 
        user, 
        userData, 
        maxFileSize, 
        maxMediaFileSize,
        maxFiles,
        callback,
        onLoading,
        onError,
        onUpdate,
        isCorrect }) => {
    const [assets, setAssets] = useState([]);
    const [open, setOpen] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(500);
    const [fileName, setFileName] = useState("");
    const [docViewerArray, setDocViewerArray] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const [fileLContentLoading, setFileContentLoading] = useState(true);
    const [srt, setSrt] = useState([]);

    const [removeFileHandler, loadingDeleteFile] = useHttpsCallable(functions, "removeFileHandler");

    const { token } = useToken();
    const { Text } = Typography;
        
    const [notificationApi, contextHolder] = notification.useNotification();
    const openNotification = ( message, description, icon ) => {
        notificationApi.open({
            message: message,
            description: description,
            icon: icon,
            duration: 3,
        });
    };

    const handleOpen = async ( file ) => {
        setOpen(true);
        setFileContentLoading(true);
        try {
            setFileUrl(file.url);
            setFileName(file.title);
            setFileExtension(file.extension);
            setSrt(file.srt);
            setFileContentLoading(false);
        } catch {
            console.error("Error fetching file text");
            setFileContentLoading(false);
        }
    }
    const handleClose = () => {
        setOpen(false);
        setFileName("");
    }
    
    const handleDelete = async (fileId, index) => {
        try {
            await removeFileHandler({ linkId: researchId, uniqueLoaderId: fileId, index: index });
            onUpdate();
        } catch (error) {
            openNotification("Error", "Failed to delete file", <ExclamationCircleTwoTone twoToneColor={token.red} />);
        }
    };
    const handleStatusCallback = async (status) => {
        if(status === "error"){
            onError();
            callback("error");
        } else if(status === "success"){
            onUpdate();
            callback("success");
        }
        else if(status === "loading"){
            onLoading();
            callback("loading");
        }
    }

    const docPreviewRenderer = (extension) => {
        let docViewer;
        if (extension === ".pdf") {
            docViewer = (
                <iframe
                    title={fileName}
                    src={fileUrl}
                    width="100%"
                    className="fileText"
                    height="calc(100vh - 108px)"
                    style={{ border: "none", height: "calc(100vh - 108px)" }} />
            );
        } else if (extension === ".csv") {
            docViewer = <CSVViewer className="fileText w-full h-full text-start" signedUrl={fileUrl} />;
        } else if (extension === ".mp3" || extension === ".mp4" || extension === ".mp4") {
            docViewer = (
                <SrtViewer
                    fileUrl={fileUrl}
                    srt={srt}
                />
            );
        } else {
            docViewer = (
                <DocViewer
                    className="fileText"
                    style={{ width: "100%", height: "calc(100vh - 108px)" }}
                    pluginRenderers={DocViewerRenderers}
                    documents={docViewerArray} />
            );
        }
        return docViewer;
    }
    const changeDrawerSize = (size) => {
        if(size === "small"){
            setDrawerWidth(500);
        }
        if(size === "medium"){
            setDrawerWidth(800);
        }
        if(size === "large"){
            setDrawerWidth(1000);
        }
        if(size === "full"){
            setDrawerWidth("calc(100% - 48px)");
        }
    }

    useEffect(( ) => {
      if (researchAssets){
        const assetsArray = researchAssets.map((item, index) => ({
            title: item.originalName,
            key: index,
            id: item.fileId,
            parsedTextURL: item.fileParsedTextURL,
            extension: item.extension,
            url: item.signedUrl,
            srt: item.transcriptionSrt,
            loaderId: item.loaderId
        }));
        const docViewerArray = researchAssets.map((item) => {
            return { uri: item.signedUrl, fileType: item.extension.replace(/^\./, "")};
        });
        setDocViewerArray(docViewerArray);
        setAssets(assetsArray);
      }
    }, [researchAssets])
   
    return (
        <Row gutter={16}>
            {contextHolder}
            <Col span={24}>
                <div style={{ width: "100%", display: "flex" }}>
                { isCorrect ? (
                    <Upload
                        researchId={researchId} 
                        uid={user.uid}
                        maxFileSize={maxFileSize}
                        maxMediaFileSize={maxMediaFileSize}
                        maxFiles={5 - assets.length}
                        statusCallback={handleStatusCallback}
                        compact
                    />
                  ) : (
                    <Result icon={<LockFilled style={{color: token.orange}} />} subTitle="You need to upgrade to our Premium tier in order to upload your files" title="Upgrade to Premium" />
                  )}
                    <Space direction="horizontal" size="small" style={{ width: "100%", overflowX: "auto"}}>
                       {
                        assets.map((item, index) => {
                            return (
                                <Card 
                                    style={{ width: "100%", maxWidth: "250px", minWidth: "250px" }}
                                    key={index}>
                                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr auto" }} wrap={false}>
                                        <div size="small" style={{ display: "grid", gap: "8px", alignItems: "center", width: "100%", gridTemplateColumns: "auto 1fr"}}>
                                            <FormatIcon extension={item.extension} />
                                            <Text ellipsis={{ tooltip: item.title }}  style={{ width: "100%", flexBasis:"100%"}} level={5}>{item.title}{item.id}</Text>
                                        </div>
                                        <div style={{ flex: "none" }}>
                                        <Button 
                                            style={{ padding: "0px 8px" }}
                                            type="link" 
                                            onClick={() => handleOpen(item)}><EyeOutlined />
                                        </Button>
                                        {assets.length > 1 && 
                                            <Popconfirm
                                                title="Are you sure you want to delete this file?"
                                                description="Altering the files will require you to regenerate the data"
                                                onConfirm={() => handleDelete(item.loaderId, index)}
                                            >
                                                <Button 
                                                    style={{ padding: "0px 8px" }}
                                                    type="link" 
                                                    danger><DeleteOutlined />
                                                </Button>
                                            </Popconfirm>
                                        }
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                       }
                        </Space>
                </div>
            </Col>

                <Drawer 
                    title={
                    <div className="flex flex-col gap-1">
                        <span className="text-center">{fileName}</span>
                        <Segmented
                            className="self-center"
                            options={[
                                { label: "Small", value: "small" },
                                { label: "Medium", value: "medium" },
                                { label: "Large", value: "large" },
                                { label: "Full", value: "full" },
                            ]}
                            onChange={changeDrawerSize}
                        />
                    </div>} 
                    mask={false} 
                    width={drawerWidth} 
                    onClose={handleClose} 
                    open={open} 
                    bodyStyle={{padding: 0}}>
                    <div style={{ whiteSpace: "pre-wrap" }}>
                    {   fileLContentLoading ? (
                        <Loader loading={fileLContentLoading} size="small" title="Loading file..." />
                    ) : (
                        docPreviewRenderer(fileExtension)
                    )
                }
                    </div>
                </Drawer>
        </Row>
    );
  }
  