import React, { useState, useEffect } from "react";
import {
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Col,
  Tooltip,
  Row,
  Card,
  message,
  Spin,
  Rate,
  Empty,
  Button,
  Typography
} from "antd";
import { db, dbRef, set, functions } from "../../firebase_setup/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { parseResponseToJson } from "../../utils";
import { Loader } from "../../components";

export const JTBDWidget = ({ researchId, researchData, editor, onRemove, presentation, user }) => {
    const [jtbd, setJtbd] = useState();
    const [loading, setLoading] = useState(false);

    const [jtbdHandler, loadingJtbd] = useHttpsCallable(functions, "generateJtbdHandler");
    const { Title, Text } = Typography

    const saveJtbd = async ( data ) => {
      try{
        const jtbdRef = dbRef(db, `research_list/${researchId}/jtbd`);
        await set(jtbdRef, data)
      } catch (error) {
        message.error(`Error saving questions: ${error}`);
      }
    }
     const extractJtbd = async () => {
        try {
            setLoading(true);
            const response = await jtbdHandler({ linkId: researchId });
            const parsedResponse = parseResponseToJson(response?.data.content);
            setJtbd(parsedResponse);
            await saveJtbd( parsedResponse );
            setLoading(false);
        } catch (error) {
            throw new Error('Failed to analyze questions' + error);
        }
    }
    useEffect(() => {
      if(researchData && researchData.jtbd){
        setJtbd(researchData.jtbd);
      } else if(researchData && !researchData.jtbd){
        extractJtbd();
      }
    }, [researchData])
    return(
      <>
        <Row gutter={16}>
          { editor && ( 
            <Col span={24}>
        
              <Row justify="space-between" align="middle" style={{ marginTop: "16px", marginBottom: "8px"}}>
                <Title style={{ margin: 0}} level={3}>
                  { loading ? (
                    <Spin style={{ marginRight: 12 }} />) : (
                    <Tooltip title="Re-analyse questions">
                      <Button type="link" icon={<SyncOutlined />} onClick={extractJtbd}/> 
                    </Tooltip>)}
                  Jobs to Be Done
                </Title> 
                <Button type="link" danger onClick={onRemove}><DeleteOutlined /></Button> 
              </Row> 
            </Col>
          )}
          <Col span={24}>
            <Row gutter={8}>
            {
              jtbd ?
              jtbd.map((job, i) =>{
                return(
                  <Col style={{ marginBottom: "8px"}} key={i} lg={12} xl={12} xxl={12} xs={24} sm={24}>
                    <Card title={job.title} extra={<Rate disabled allowHalf defaultValue={(job.rank/2)} />}>
                    <Row gutter={16}>
                      <Col span={24} style={{ marginBottom: 16 }}>
                        <Text>{job.description}</Text>
                      </Col>
                      <Col span={12}>
                        <Card title="Motivation" bordered={false} size="small">
                          {job.motivation}
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card title="Desired Outcome" bordered={false} size="small">
                          {job.desired_outcome}
                        </Card>
                      </Col>
                    </Row>
                    </Card>
                  </Col>)
              }):
                <Card style={{ width: "100%" }}>
                    { loading ? 
                      ( <Loader size="small" title="Generating Jobs to be Done..."  loading={loading}/> ) : ( 
                        <Row justify="center">
                          <Empty description="Jobs to be Done couldn't be generated">
                            { editor && <Button type="primary"  onClick={() => extractJtbd()}><SyncOutlined /> Try Again</Button> }
                          </Empty>
                        </Row> )
                    }
                </Card>
            }
            </Row>
          </Col>
        </Row>
      </>
    );
  }
