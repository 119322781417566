import React, { useState, useEffect } from "react";
import {
  LinkOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Row,
  Card,
  message,
  Empty,
  Button,
  Typography,
  Spin,
  Tooltip
} from "antd";
import { db, dbRef, set, functions } from "../../firebase_setup/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { parseResponseToJson } from "../../utils";
import { Loader } from "../../components";

export const CompetiorsWidget = ({ researchId, summary, researchData, editor, onRemove, presentation, user }) => {
    const [competitorsInfo, setCompetitorsInfo] = useState();
    const [loading, setLoading] = useState(false);

    const [ competitorsHandler, loadingCompetitors ] = useHttpsCallable(functions, "generateCompetitorsHandler");
    const { Title } = Typography
    const saveCompetitorsToDb = async ( competitors ) => {
      try{
        const competitorsRef = dbRef(db, `research_list/${researchId}/competitors`);
        await set(competitorsRef, competitors)
      } catch (error) {
        message.error(`Error saving persona: ${error}`);
      }
    }
     const lookoutCompetitors = async () => {
      setLoading(true);
      try {
        if(researchData){
          const response = await competitorsHandler({ linkId: researchId });
          const parsedResponse = parseResponseToJson(response?.data.content);
          setCompetitorsInfo(parsedResponse);
          saveCompetitorsToDb(parsedResponse);
        }
      } catch (error) {
        throw new Error('Failed to find competitors' + error);
      }
      setLoading(false);
    }
    useEffect(() => {
      if(researchData && researchData.competitors){
        setCompetitorsInfo(researchData.competitors);
      } else {
        lookoutCompetitors();
      }
    }, [researchData])
    return(
      <>
        <Row gutter={16}>
          <Col span={24}>
          { !presentation && 
            <Row justify="space-between" align="middle" style={{ marginTop: "16px", marginBottom: "8px"}}>
              <Title style={{ margin: 0}} level={3}>
                { editor && ( 
                  loading ? (
                    <Spin style={{ marginRight: 12 }} />) : (
                    <Tooltip title="Re-generate competitors">
                      <Button type="link" icon={<SyncOutlined />} onClick={lookoutCompetitors}/> 
                    </Tooltip>)
                )}
                Competitors
              </Title> 
              { editor && 
              <Button type="link" danger onClick={onRemove}><DeleteOutlined /></Button> }
            </Row> }
          </Col>
          <Col span={24}>
            <Row gutter={16}>
            {
              competitorsInfo ?
              competitorsInfo.map((competitor, i) =>{
                return(
                  <Col style={{ marginBottom: "16px"}} key={i} xxl={4} xl={6} lg={10} md={12} xs={24}>
                    <Card title={competitor.name}>
                      <p>{competitor.description}</p>
                      <Divider />
                        <a target="_blank" rel="noreferrer" href={competitor.website}><LinkOutlined/> Visit Website</a>
                    </Card>
                  </Col>)
              }):
                <Card style={{ width: "100%" }}>
                    { loading ? 
                      ( <Loader size="small" title="Looking Up Competitors..."  loading={loading}/> ) : ( 
                        <Row justify="center">
                          <Empty description="Couldn't gather competitors">
                            { editor && <Button type="primary"  onClick={() => lookoutCompetitors(summary)}><SyncOutlined /> Try Again</Button> }
                          </Empty>
                        </Row> )
                    }
                </Card>
            }
            </Row>
          </Col>
        </Row>
      </>
    );
  }
