import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Papa from "papaparse";

const CSVViewerAntd = ({ signedUrl, className, style }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (signedUrl) {
      fetchCSVData(signedUrl);
    }
  }, [signedUrl]);

  const fetchCSVData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url);
      const csvText = await response.text();

      // Parse the CSV data
      Papa.parse(csvText, {
        header: true, // Assumes the first row is the header
        skipEmptyLines: true,
        complete: (result) => {
          const parsedData = result.data;

          // Dynamically generate columns based on CSV headers
          const columnHeaders = Object.keys(parsedData[0]).map((key) => ({
            title: key, // Column header
            dataIndex: key, // Key in the data object
            key: key, // Unique identifier
          }));

          setData(parsedData); // Set table data
          setColumns(columnHeaders); // Set table columns
        },
        error: (err) => {
          console.error("Error parsing CSV: ", err);
        },
      });
    } catch (err) {
      console.error("Error fetching CSV: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
      <Table
        style={style}
        className={`${className} rs-csv-viewer`}
        columns={columns}
        dataSource={data}
        virtual={true}
        loading={loading}
        rowKey={(record, index) => index} // Use index as unique key
        pagination={false}
      />
  );
};

export default CSVViewerAntd;
