import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

export function SrtViewer({ srt, fileUrl, className }) {
    const [currentSubtitle, setCurrentSubtitle] = useState("");

    const playerRef = useRef(null);
    const subtitlesRef = useRef({});
    const subtitleContainerRef = useRef(null);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    const handleProgress = ({ playedSeconds }) => {
        // Ensure data and data.srt are defined before accessing them
        if (srt) {
        const current = srt.find((sub) => {
            return (
            playedSeconds >= sub.startSeconds && playedSeconds <= sub.endSeconds
            );
        });
        if (current) {
            setCurrentSubtitle(current.text);
        } else {
            setCurrentSubtitle("");
        }
        }
    };
    // Function to convert the timestamp
    const convertTimestamp = (timestamp) => {
        // Split the timestamp to get hours, minutes, and seconds
        const parts = timestamp.split(",");
        const timePart = parts[0]; // "00:00:00"
        return timePart; // Return the time part without milliseconds
    };

    // Function to seek the player to the specified time
    const seekTo = (time) => {
        const parts = time.split(":");
        const seconds = +parts[0] * 60 * 60 + +parts[1] * 60 + +parts[2];
        playerRef.current.seekTo(seconds, "seconds");
    };
    useEffect(() => {
        if (currentSubtitle && subtitlesRef.current[currentSubtitle]) {
          subtitlesRef.current[currentSubtitle].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }, [currentSubtitle]);

    return (
        <div ref={subtitleContainerRef} className={`w-full p-6 ${className}`}>
        <div className="flex justify-center items-center w-full py-6 bg-white sticky top-0">
            <div className="w-full flex justify-center relative bg-black rounded-[32px] overflow-hidden">
            <ReactPlayer
                ref={playerRef}
                onProgress={handleProgress}
                url={fileUrl}
                className="rounded-xl w-full overflow-hidden h-full"
                controls={true}
                height={256}
                playing={false} // Do not autoplay
            />
            </div>
        </div>
        <div className="flex flex-col gap-4">
            {   srt &&
                srt.map((sub) => (
                    <div
                    onClick={() => seekTo(convertTimestamp(sub.endTime))}
                    key={sub.id}
                    ref={(el) => (subtitlesRef.current[sub.text] = el)}
                    className={`
                        subtitle-element rounded-2xl border-b border-solid border-slate-100 cursor-pointer ease-in duration-100 items-start flex gap-4 p-4 pb-6 
                        ${currentSubtitle === sub.text
                        ? "bg-primary-50 text-primary-500"
                        : ""
                    }`}
                    >
                    <div
                        className={`rs-video-timestamp flex flex-col p-3 select-none rounded-xl text-sm 
                            ${currentSubtitle === sub.text
                            ? "bg-white text-bold font-bold shadow-md"
                            : "font-medium "
                        }`}
                    >
                        <span>{formatTime(sub.startSeconds)}</span>
                        <span>{formatTime(sub.endSeconds)}</span>
                    </div>
                    <p className={`pt-2 font-medium fileText`}>{sub.text}</p>
                </div>
            ))}
        </div>
        </div>
    );
}
