import React, { useState, useEffect } from "react";
import {
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Col,
  Tag,
  Tooltip,
  Row,
  Card,
  message,
  Spin,
  Empty,
  Space,
  Button,
  Typography
} from "antd";
import { db, dbRef, set, functions } from "../../firebase_setup/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { parseResponseToJson } from "../../utils";
import { Loader } from "../../components";
import { rsQuestions } from "../../utils";

export const QuestionsWidget = ({ researchId, summary, researchData, editor, onRemove, presentation, user }) => {
    const [questions, setQuestions] = useState();
    const [loading, setLoading] = useState(false);

    const [ questionsHandler, loadingQuestions ] = useHttpsCallable(functions, "extractQuestionsHandler");
    const { Title, Text } = Typography
    const saveQuestions = async ( data ) => {
      try{
        const questionsRef = dbRef(db, `research_list/${researchId}/questions`);
        await set(questionsRef, data)
      } catch (error) {
        message.error(`Error saving questions: ${error}`);
      }
    }
     async function analyzeQuestions() {
        try {
            setLoading(true);
            const response = await questionsHandler({ linkId: researchId });
            const parsedResponse = parseResponseToJson(response?.data.content);
            setQuestions(parsedResponse);
            saveQuestions( parsedResponse );
            setLoading(false);
        } catch (error) {
            throw new Error('Failed to analyze questions' + error);
        }
    }
    useEffect(() => {
      if(researchData && researchData.questions){
        setQuestions(researchData.questions);
      } else if(researchData && !researchData.questions){
        analyzeQuestions();
      }
    }, [researchData])
    return(
      <>
        <Row gutter={16}>
          <Col span={24}>
          { !presentation && 
            <Row justify="space-between" align="middle" style={{ marginTop: "16px", marginBottom: "8px"}}>
              <Title style={{ margin: 0}} level={3}>
              { editor && ( 
                loading ? (
                  <Spin style={{ marginRight: 12 }} />) : (
                  <Tooltip title="Re-analyse questions">
                    <Button type="link" icon={<SyncOutlined />} onClick={analyzeQuestions}/> 
                  </Tooltip>)
              )}
                Questions Analysis
              </Title> 
              { editor && 
              <Button type="link" danger onClick={onRemove}><DeleteOutlined /></Button> }
            </Row> }
          </Col>
          <Col span={24}>
            <Row gutter={8}>
            {
              questions ?
              questions.map((question, i) =>{
                return(
                  <Col style={{ marginBottom: "8px"}} key={i} span={24}>
                    <Card>
                    <Row justify="center" gutter={16} direction="vertical">
                      <Col span={10}>
                        <Title level={5}>{question.question}</Title>
                      </Col>
                      <Col span={8}>
                        <Space size="small" direction="vertical">
                          <Text type="secondary">Category</Text>
                          <Tag color="geekblue">{question.category}</Tag>
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Space size="small" direction="vertical">
                          <Text type="secondary">Type</Text>
                          <Tag color="purple">{question.type}</Tag>
                        </Space>
                      </Col>
                    </Row>
                    </Card>
                  </Col>)
              }):
                <Card style={{ width: "100%" }}>
                    { loading ? 
                      ( <Loader size="small" title="Analysing Questions..."  loading={loading}/> ) : ( 
                        <Row justify="center">
                          <Empty description="Questions couldn't be analyzed">
                            { editor && <Button type="primary"  onClick={() => analyzeQuestions()}><SyncOutlined /> Try Again</Button> }
                          </Empty>
                        </Row> )
                    }
                </Card>
            }
            </Row>
          </Col>
        </Row>
      </>
    );
  }
