import React, { useState, useEffect } from "react";
import { DeleteOutlined, UserOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Space,
  Col,
  Avatar,
  Row,
  Card,
  message,
  Empty,
  Button,
  Typography,
  Tooltip,
  Spin,
} from "antd";
import { db, dbRef, set, functions } from "../../firebase_setup/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { parseResponseToJson, checkEmptyResponse } from "../../utils";
import styles from "../WidgetStyles.module.css";
import { Loader } from "../../components";

export const PersonaWidget = ({
  researchId,
  researchData,
  editor,
  presentation,
  onRemove,
}) => {
  const [personasInfo, setPersonasInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);

  const [personaHandler, loadingPersona] = useHttpsCallable(
    functions,
    "generatePersonasHandler"
  );

  const { Text, Title } = Typography;

  const savePersonaToDb = async (persona) => {
    try {
      const personaRef = dbRef(db, `research_list/${researchId}/user_personas`);
      await set(personaRef, persona);
    } catch (error) {
      message.error(`Error saving persona: ${error}`);
    }
  };

  async function handlePersona() {
    setLoading(true);
    try {
      const response = await personaHandler({ linkId: researchId });
      const parsedResponse = response && parseResponseToJson(response?.data.content);
      setPersonasInfo(parsedResponse);
      await savePersonaToDb(parsedResponse);
    } catch (error) {
      throw new Error("Failed to generate persona" + error);
    }
    setLoading(false);
  }
  const singlePersonaComponent = () => {
    return (
      personasInfo && (
        <Card className={styles.personaCard}>
        <Row gutter={24}>
          <Col xl={7} lg={8} md={8} xs={24}>
            <Space style={{ padding: "8px" }} align="start" size="small">
              <Avatar size={42} icon={<UserOutlined />} />
              <div>
                {personasInfo.name && (
                  <Title style={{ margin: "0" }} level={1}>
                    {personasInfo.name}
                  </Title>
                )}
                {personasInfo.occupation && (
                  <Text style={{ margin: "0" }} type="secondary">
                    {personasInfo.occupation}
                  </Text>
                )}
                <div style={{ marginTop: "16px" }}>
                  {personasInfo.age && (
                    <div>
                      <Text>Age: {personasInfo.age}</Text>
                    </div>
                  )}
                  {personasInfo.location && (
                    <div>
                      <Text>Location: {personasInfo.location}</Text>
                    </div>
                  )}
                </div>
              </div>
            </Space>
          </Col>
  
          <Col xl={17} lg={16} md={16} xs={24} style={{ paddingTop: "24px" }}>
            <>
              {personasInfo.background && (
                <div>
                  <Title level={3}>Background</Title>
                  <Text>{personasInfo.background}</Text>
                </div>
              )}
  
              <Row gutter={24}>
                {personasInfo.goals && (
                  <div className={styles.personaInfo}>
                    <Title level={3}>Goals</Title>
                    <ul>
                      {personasInfo.goals.map((goal, index) => (
                        <li key={index}>{goal}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {personasInfo.pain_points && (
                  <div className={styles.personaInfo}>
                    <Title level={3}>Pain Points</Title>
                    <ul>
                      {personasInfo.pain_points.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {personasInfo.insights && (
                  <div className={styles.personaInfo}>
                    <Title level={3}>Insight</Title>
                    <ul>
                      {personasInfo.insights.map((insight, index) => (
                        <li key={index}>{insight}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Row>
            </>
          </Col>
        </Row>
        </Card>
      )
    )
  }
  const multiplePersonaComponent = () => {
    return(
      personasInfo && 
      personasInfo.map((persona, index) => (
        <Card className='mb-2'>
  
        <Row gutter={24} key={index}>
          <Col xl={7} lg={8} md={8} xs={24}>
            <Space style={{ padding: "8px" }} align="start" size="small">
              <Avatar size={42} icon={<UserOutlined />} />
              {empty}
              <div>
                {persona.name && (
                  <Title style={{ margin: "0" }} level={1}>
                    {persona.name}
                  </Title>
                )}
                {persona.occupation && (
                  <Text style={{ margin: "0" }} type="secondary">
                    {persona.occupation}
                  </Text>
                )}
                <div style={{ marginTop: "16px" }}>
                  {persona.age && (
                    <div>
                      <Text>Age: {persona.age}</Text>
                    </div>
                  )}
                  {persona.location && (
                    <div>
                      <Text>Location: {persona.location}</Text>
                    </div>
                  )}
                </div>
              </div>
            </Space>
          </Col>
  
          <Col xl={17} lg={16} md={16} xs={24} style={{ paddingTop: "24px" }}>
            <>
              {persona.background && (
                <div>
                  <Title level={3}>Background</Title>
                  <Text>{persona.background}</Text>
                </div>
              )}
  
              <Row gutter={24}>
                {persona.goals && (
                  <div className={styles.personaInfo}>
                    <Title level={3}>Goals</Title>
                    <ul>
                      {persona.goals.map((goal, index) => (
                        <li key={index}>{goal}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {persona.pain_points && (
                  <div className={styles.personaInfo}>
                    <Title level={3}>Pain Points</Title>
                    <ul>
                      {persona.pain_points.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {persona.insights && (
                  <div className={styles.personaInfo}>
                    <Title level={3}>Insight</Title>
                    <ul>
                      {persona.insights.map((insight, index) => (
                        <li key={index}>{insight}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Row>
            </>
          </Col>
        </Row>
      </Card>
    )
  ));
    
  };
  useEffect(() => {
    if(researchData){
      if (researchData.persona) {
        setPersonasInfo(researchData.persona);
        setLoading(false);
      } else if (researchData.user_persona) {
        setPersonasInfo(researchData.user_persona);
        setLoading(false);
      } else if (researchData.user_personas) {
        setPersonasInfo(researchData.user_personas);
        setLoading(false);
      } else if (researchData.summary) {
        handlePersona();
      }
    }
  }, [researchData]);
  useEffect(() => {
    if(personasInfo){
      const isEmpty = checkEmptyResponse(personasInfo);
      setEmpty(isEmpty);
    }
  }, [personasInfo]);
  return (
    <Row gutter={16}>
      <Col span={24}>
        {!presentation && (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: "16px", marginBottom: "8px" }}
          >
            <Title style={{ margin: 0 }} level={3}>
              {editor &&
                (loading ? (
                  <Spin style={{ marginRight: 12 }} />
                ) : (
                  <Tooltip title="Re-generate personas">
                    <Button
                      type="link"
                      icon={<SyncOutlined />}
                      onClick={handlePersona}
                    />
                  </Tooltip>
                ))}
              User Personas
            </Title>
            {editor && (
              <Button type="link" danger onClick={onRemove}>
                <DeleteOutlined />
              </Button>
            )}
          </Row>
        )}
      </Col>
      <Col span={24}>
          { personasInfo ? (
            empty ? (
              <Card>
                <Empty description="Not enough context to generate personas">
                  {editor && (
                    <Button type="primary" onClick={() => handlePersona()}>
                      <SyncOutlined /> Try Again
                    </Button>
                  )}
                </Empty> 
              </Card>
            ) : (
              Array.isArray(personasInfo) ? multiplePersonaComponent() : singlePersonaComponent()
            )
          ) : ( 
          loading ? (
          <Loader
            title="Generating User Personas"
            size="small"
            loading={loading}
          />
          ) : (
          <Empty description="Persona couldn't be generated">
            {editor && (
              <Button type="primary" onClick={() => handlePersona()}>
                <SyncOutlined /> Try Again
              </Button>
            )}
          </Empty> 
          )
        )}
      </Col>
    </Row>
  );
};
