import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, message, Card, Badge } from "antd";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { functions, storage } from "../../firebase_setup/firebase"; // Import Firebase setup
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { DeleteOutlined, UploadOutlined, FileOutlined, CloudUploadOutlined, LockFilled } from "@ant-design/icons";
import { Alert, Avatar } from "antd";
import {
  CSVIcon,
  DOCXIcon,
  PDFIcon,
  MP3Icon,
  MP4Icon,
  PPTXIcon,
  XLSXIcon,
  WAVIcon,
  WEBMIcon,
} from "../../utils";
import { PremiumCheckout } from "../premium-checkout/PremiumCheckout";
import { FormatIcon } from "../../components";
export function Upload({ 
  description, 
  researchId,
  uid,
  maxFileSize, // General file size limit in bytes
  maxMediaFileSize, // Specific size limit for media files (mp3, mp4, wav) in bytes
  maxFiles, // Maximum number of files allowed
  statusCallback,
  userData,
  compact 
}) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  // Firebase callable function
  const [processFilesCallable, loading] = useHttpsCallable(functions, "uploadFilesHandler");

  const handelUpgradeModalClose = () => {
    setUpgradeModalOpen(false);
  };
  const UploadAlert = () => {
    if(userData && userData.subscriptionStatus === "Trial") {
      return (
        <Alert
          message="Upgrade to upload more and larger files"
          description={
            <div className="flex items-start flex-col gap-2">
              <span>
                Upgrade to a paid plan to upload more files and larger files.
              </span>
              <Button type="primary" className="!bg-amber-500 bg-text-white" onClick={() => setUpgradeModalOpen(true)}><CloudUploadOutlined /> Upgrade Now</Button>
            </div>
          }
          type="warning"
          className="w-full mb-4"
          action={<Button type="link" onClick={resetError}>X</Button>}
          showIcon/>
      )
    } 
    else {
      return(
        <Alert
          message="Some file(s) had issues"
          description={errorMessage}
          type="error"
          showIcon
          className="w-full mb-4"
          action={<Button type="link" danger onClick={resetError}>X</Button>}
        />      
      )
    }
  }
  const UploadFormatIcons = () => {
    if(userData && userData.subscriptionStatus === "Trial") {
      return (
        <div className="flex justify-center items-center gap-6 py-6">
            <img
              className={"h-[42px]"}
              src={PDFIcon}
              alt="PDF file format icon"
            />
            <img
              className={"h-[42px]"}
              src={DOCXIcon}
              alt="DOCX file format icon"
            />
            <img
              className={"h-[42px]"}
              src={CSVIcon}
              alt="CSV file format icon"
            />
            <img
              className={"h-[42px]"}
              src={PPTXIcon}
              alt="PPTX file format icon"
            />
            <img
              className={"h-[42px]"}
              src={XLSXIcon}
              alt="XLSX file format icon"
            />
            <Badge count={<div className="w-[24px] h-[24px] bg-amber-500 rounded-full flex items-center justify-center"><LockFilled className="text-white" /></div>}>
              <img
                className={"h-[42px] opacity-60"}
                src={MP3Icon}
                alt="MP3 file format icon"
              />
            </Badge>
            <Badge count={<div className="w-[24px] h-[24px] bg-amber-500 rounded-full flex items-center justify-center"><LockFilled className="text-white" /></div>}>
              <img
                className={"h-[42px] opacity-60"}
                src={MP4Icon}
                alt="MP4 file format icon"
              />
            </Badge>
            <Badge count={<div className="w-[24px] h-[24px] bg-amber-500 rounded-full flex items-center justify-center"><LockFilled className="text-white" /></div>}>
              <img
                className={"h-[42px] opacity-60"}
                src={WAVIcon}
                alt="WAV file format icon"
              />
            </Badge>
            <Badge count={<div className="w-[24px] h-[24px] bg-amber-500 rounded-full flex items-center justify-center"><LockFilled className="text-white" /></div>}>
              <img
                className={"h-[42px] opacity-60"}
                src={WEBMIcon}
                alt="WEBM file format icon"
              />
            </Badge>
          </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center gap-6 py-6">
            <img
              className={"h-[42px]"}
              src={PDFIcon}
              alt="PDF file format icon"
            />
            <img
              className={"h-[42px]"}
              src={DOCXIcon}
              alt="DOCX file format icon"
            />
            <img
              className={"h-[42px]"}
              src={CSVIcon}
              alt="CSV file format icon"
            />
            <img
              className={"h-[42px]"}
              src={PPTXIcon}
              alt="PPTX file format icon"
            />
            <img
              className={"h-[42px]"}
              src={XLSXIcon}
              alt="XLSX file format icon"
            />
            <img
              className={"h-[42px]"}
              src={MP3Icon}
              alt="MP3 file format icon"
            />
            <img
              className={"h-[42px]"}
              src={MP4Icon}
              alt="MP4 file format icon"
            />
            <img
              className={"h-[42px]"}
              src={WAVIcon}
              alt="WAV file format icon"
            />
            <Badge count={<LockFilled className="text-white" />}>
            <div>

              <img
                className={"inline-block h-[42px]"}
                src={WEBMIcon}
                alt="WEBM file format icon"
              />
            </div>
            </Badge>
          </div>
      );
    }
  }
  const onDrop = useCallback(
    (acceptedFiles) => {
      const validatedFiles = [];
      const errors = [];
  
      // Check if adding new files exceeds the maximum allowed count
      if (files.length + acceptedFiles.length > maxFiles) {
        if (userData && userData.subscriptionStatus === "Trial") {
          setUpgradeModalOpen(true);
        }
        errors.push(`You can upload a maximum of ${maxFiles} files.`);
      } else {
        acceptedFiles.forEach((file) => {
          const fileType = file.type.split("/")[1]; // Get the file extension
          const isMedia = ["mp3", "mp4", "wav"].includes(fileType);
          const sizeLimit = isMedia ? maxMediaFileSize : maxFileSize;
  
          if (file.size > sizeLimit) {
            if (userData && userData.subscriptionStatus === "Trial") {
              setUpgradeModalOpen(true);
            }
            errors.push(
              `${file.name} exceeds the size limit of ${(sizeLimit / 1024 / 1024).toFixed(2)} MB.`
            );
          } else {
            validatedFiles.push(file); // Add valid files to the list
          }
        });
      }
  
      // Set error messages for invalid files
      if (errors.length) {
        setErrorMessage(errors.join("\n"));
        message.error(errors.join("\n"));
      }
  
      // Update the state with validated files
      if (validatedFiles.length) {
        setFiles((prevFiles) => [...prevFiles, ...validatedFiles]);
      }
    },
    [files.length, maxFiles, maxFileSize, maxMediaFileSize, userData]
  );

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };
  const resetAllFiles = (e) => {
    e.preventDefault();
    
    setFiles([]);
  };
  const resetError = () => {
    setErrorMessage("");
  };
  const uploadToBackend = async () => {
    if (!files.length) {
      message.error("No files selected for upload.");
      return;
    }

    setUploading(true);
    statusCallback("loading");

    try {
      const uploadedFileDetails = [];
      for (const file of files) {
        // Upload file to Firebase Storage
        const fileRef = ref(storage, `files/${uid}/${researchId}/${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadUrl = await getDownloadURL(fileRef);

        // Write metadata to Firestore
        const fileMetadata = {
          researchId,
          filePath: `files/${uid}/${researchId}/${file.name}`,
          originalName: file.name,
          url: downloadUrl,
          type: file.type,
          size: file.size,
        };
        uploadedFileDetails.push({ ...fileMetadata });
      }

      // Pass file details to the backend for Qdrant processing
      const response = await processFilesCallable({
        researchId,
        files: uploadedFileDetails,
      });
      if (response?.data?.success) {
        statusCallback("success");
      } else {
        statusCallback("error");
      }
      setFiles([]);
    } catch (err) {
      console.error("Upload error:", err);
      statusCallback("error");
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'audio/mpeg': ['.mp3'],
      'video/mp4': ['.mp4'],
      'audio/wav': ['.wav'],
    }
  });

  return (
    compact ? 
    (
      <div className="relative flex flex-none gap-2 mr-3 items-center">
      <div
        {...getRootProps()}
        className={`border-dashed border-2 px-8 py-4 h-full w-[240px] cursor-pointer rounded-2xl w-full flex flex-col items-center justify-center text-primary-600 border-primary-200 bg-primary-100 hover:border-primary-300  active:border-primary-500 active:bg-primary-200 gap-6 
          ${isDragActive ? "border-primary-500" : "border-primary-200"}`}
      >
        <input {...getInputProps()} />
        { files.length > 0 ? (
          <div className="relative font-semibold flex flex-col justify-center items-center text-center">
            <span>{files.length} files selected</span>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center text-center">
            <span className="font-bold">+ Add files</span>
          </div>
        )}
      </div>
      
      { files.length > 0 &&
        <div className="flex items-center gap-2">
          <Button
            type="primary"
            onClick={uploadToBackend}
            loading={uploading || loading}
            disabled={!files.length}
          >
            <UploadOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={resetAllFiles}
            disabled={uploading || loading}
          >
            <DeleteOutlined />
          </Button>
        </div>
      }
    </div>
    ) : (
      <div className="flex flex-col items-center justify-center">
      {errorMessage &&
        UploadAlert()
      }
      <div
        {...getRootProps()}
        className={`border-dashed bg-white border-2 p-6 cursor-pointer rounded-2xl w-full flex flex-col items-center hover:border-primary-300 active:border-primary-500 active:bg-primary-50 ${
          isDragActive ? "border-primary-500" : "border-gray-300"
        }`}
      >
        <input {...getInputProps()} />
        <div className="flex items-center">
          <div className="flex items-center justify-center w-24 h-24 bg-primary-100 text-primary-600 rounded-2xl">
            <CloudUploadOutlined className="text-6xl" />
          </div>
        </div>
        {  UploadFormatIcons() }
        <div className="flex flex-col items-center">
          <h1 className="title-2xl m-0 font-bold text-center">Upload Files to Start Analysis</h1>
          <p className="text-center">Drag & drop files here, or <strong className="text-primary-500">click to select files</strong></p>
          {description && <div>{description}</div>}
        </div>
      </div>
      {files.length > 0 && (
        <div className="flex flex-col items-center w-1/2 mt-4">
          <h3 className="text-lg font-semibold">Selected Files</h3>
          {files.map((file) => (
  
            <Card className="w-full" key={file.name}>
              <div className="flex w-full justify-between items-center">
                <span className="flex items-center">
                  <FormatIcon extension={"." + file.name.split(".").pop()} />
                  {file.name}
                </span>
                <Button type="link" className="text-red-500" onClick={() => handleRemoveFile(file)}>
                  <DeleteOutlined />
                </Button>
              </div>
            </Card>
          ))}
        </div>
      )}
      <Button
        type="primary"
        onClick={uploadToBackend}
        loading={uploading || loading}
        disabled={!files.length}
        className="mt-4"
      >
        Upload Files
      </Button>
    <PremiumCheckout modalOpen={upgradeModalOpen} modalCloseMethod={handelUpgradeModalClose} />
    </div> 
    )
  );
}
