import React, { useState, useEffect, useRef } from "react";
import {
  SendOutlined,
} from "@ant-design/icons";
import {
  theme,
  message,
  Spin,
  Card,
  Tag,
  Input,
  Button,
  Tooltip,
  Switch,
} from "antd";
import { ChatBubble } from "../../components";
import { dbRef, db, set, update, functions } from "../../firebase_setup/firebase";

import MarkdownPreview from '@uiw/react-markdown-preview';
import { FREE_RESEARCH_MESSAGE_LIMIT, generateReply, rsReply } from '../../utils'
import { useHttpsCallable } from "react-firebase-hooks/functions";
import styles from "../WidgetStyles.module.css";

const MemoizedMarkdownPreview = React.memo(({ source }) => {
    return <MarkdownPreview source={source} />;
});

const { useToken } = theme;

const MessageInput = React.memo(({ chatMessage, handleSend, onMessageChange }) => {
    return (
      <div className={styles.chatBox}>
  
          <Input
            allowClear
            value={ chatMessage }
            defaultValue=""
            onChange={onMessageChange}
            onPressEnter={handleSend}
            placeholder="Chat here..."
            suffix={
              <Button 
                type="text"
                onClick={handleSend}>
                  <SendOutlined />
                </Button>
              }
          />
      </div>
    );
});
  
const Messages = React.memo(({ messages, researchId }) => {

  const addToResearch = async (e, messageId) => {
    try {
      const message = messages.find(m => m.id === messageId);
      if (message) {
        const answersRef = dbRef(db, `research_list/${researchId}/messages/${messageId}/`);
        // Toggle the 'added' attribute based on its current state
        await update(answersRef, { added: !message.added });
      }
    } catch (error) {
      console.error(`Couldn't add to research: ${error}`); // Changed to console.error for better error handling
    }
  };

  return(
    <>
      {messages && messages.map((message) => (
        <ChatBubble
          key={message.id}
          extra={
            message.sender !== 'user' && (message.added ? (
                <Button onClick={(e) => addToResearch(e, message.id)} danger type="link" >Remove from research</Button> ) : (
                <Button onClick={(e) => addToResearch(e, message.id)} type="link" >+ Add to research</Button>))}
          text={ 
            message.sender !== 'user' ? (
            message.external ? 
              <><div style={{ marginBottom: '8px' }}><Tag color="purple">Context + Broad Knowledge</Tag></div><MemoizedMarkdownPreview source={message.text} /></> : 
              <><div style={{ marginBottom: '8px' }}><Tag color="orange">Context Only</Tag></div><MemoizedMarkdownPreview source={message.text} /></> 
              ) : <MemoizedMarkdownPreview source={message.text} /> }
          status={message.sender === 'user' ? 'sent' : undefined}
        />
      ))}
    
    </> 
  )
});
export const ChatWidget = ({ user, userData, researchData, researchId, editor}) => {
    const [ messages, setMessages ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ chatMessage, setChatMessage ] = useState();
    const [ externalChat, setExternalChat ] = useState(false);

    const { token } = useToken();
    const messagesEndRef = useRef(null)
    
    const [replyHandler, loadingReply] = useHttpsCallable(functions, "askQuestionHandler");
    const handleChatKnowledge = () => {
        setExternalChat(!externalChat);
    }
    const saveMessagesToDb = async (data) => {
      try {
        data.forEach(async (message) => {
          const messageRef = dbRef(db, `research_list/${researchId}/messages/${message.id}`);
          await set(messageRef, message);
        });
      } catch (error) {
        
        message.error(`Error saving messages: ${error}`);
      }
    };
  
    const getReply = async ( question, researchId, externalChat ) => {
      setLoading(true);
      try {
        const response = await replyHandler({ question, linkId: researchId, externalChat });
        const newMessage = {
          id: Date.now(),
          sender: 'bot',
          text: response?.data.content,
          external: externalChat === true ? true : ""
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setLoading(false);
        saveMessagesToDb([newMessage]);
      } catch (error) {
        message.error('Failed to generate reply' + error);
      }
    };
    
    const handleSend =  () => {
      const newMessage = {
        id: Date.now(),
        sender: 'user',
        text: chatMessage,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      getReply( chatMessage, researchId, externalChat);
      saveMessagesToDb([newMessage]);
      setChatMessage("");
      scrollToBottom();
    };
    
    
    useEffect(() => {
      if(researchData && researchData.messages){
        setMessages(Object.values(researchData.messages));
      } 
    }, [researchData]);
    
    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    const scrollToBottom = () => {
      const container = document.querySelector('.chatCard .ant-card-body');
      const target = document.getElementById("end-of-messages");

      // Calculate target position relative to the container
      const targetPosition = target.offsetTop - container.offsetTop;
      
      // Scroll container to target position
      container.scrollTo({ top: targetPosition, behavior: "smooth" });
    };
    
    return (
      <Card
        title="AI Assistant"
        className="chatCard"
        bodyStyle={{
          height: "calc(100vh - 106px)",
          minHeight: "400px",
          overflow: "auto",
          paddingBottom: "72px",
          backgroundColor: token.colorBgLayout,
          borderRadius: 0,
        }}
        extra={ editor && 
            <Tooltip title="Broad knowledge means the chat can use it's own knowledge on top of the data you provided">
                <label style={{ display: "Flex", gap: "8px" }}>
                Broad knowledge
                <Switch checked={externalChat} onChange={handleChatKnowledge} />
                </label>
            </Tooltip>
        }
        actions={ editor && [
          <MessageInput 
            chatMessage={chatMessage} 
            handleSend={handleSend} 
            researcId={researchId}
            messageLimitExceeded={messages.length > FREE_RESEARCH_MESSAGE_LIMIT && userData.subscriptionStatus === "Free"}
            onMessageChange={e => setChatMessage(e.target.value)} 
          />
        ]}
      >
        <Messages researchId={researchId} messages={messages} />
        <div style={{ height: "72px" }} id="end-of-messages" ref={messagesEndRef} />
        {loading  &&  <ChatBubble text={<Spin />} />}
      </Card>
    );
  }