import React, { useState, useEffect } from "react";
import {
  SmileOutlined,
  InfoCircleOutlined,
  FrownOutlined,
  MehOutlined,
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import {
  Space,
  Tag,
  Row,
  Card,
  notification,
  theme,
} from "antd";
import { dbRef, db, set, functions } from "../../firebase_setup/firebase"
import { api, rsSentiment } from "../../utils";
import { Loader } from "../../components";
import styles from "../WidgetStyles.module.css";
import { useHttpsCallable } from "react-firebase-hooks/functions";

const { useToken } = theme

export const SentimentWidget = ({ researchId, summary, researchData, editor, presentation, user }) => {
    const { token } = useToken();
  
    const [sentiment, setSentiment] = useState();
    const [loading, setLoading] = useState(true);

    const [sentimentHandler, loadingSentiment] = useHttpsCallable(functions, "generateSentimentHandler");
    const [notificationApi, contextHolder] = notification.useNotification();
  
    const openNotification = ( message, description, icon ) => {
      notificationApi.open({
          message: message,
          description: description,
          icon: icon,
          duration: 3,
      });
    };
    const saveSentimentToDb = async( data ) => {
        try {
          const sentimentRef = dbRef(db, `research_list/${researchId}/sentiment`);
          await set(sentimentRef, data);
        } catch (error){
          openNotification(
            "Error saving sentiment", 
            "We couldn't save the sentiment to the database", 
            <ExclamationCircleTwoTone twoToneColor={token.colorError} />
          );
        }
    }

    const getSentiment = async ( ) => {
      try {
        const response = await sentimentHandler({ linkId: researchId });
        setSentiment(response.data.content);
        saveSentimentToDb(response.data.content);
      } catch (error) {
        throw new Error('Failed to generate sentiment');
      }
    }
    const sentimentColor = (sentiment) => {
      let color;
      const sentimentText = sentiment && sentiment.toLowerCase();
      if (sentiment && sentimentText.includes("neutral")) {
        color = token.colorWarning;
      } else if (sentiment && sentimentText.includes("negative")) {
        color = token.colorError;
      } else if (sentiment && sentimentText.includes("positive")) {
        color = token.colorSuccess;
      } else if (sentiment && sentimentText.includes("mixed")) {
        color = token.colorPrimary;
      } else {
        color = token.colorFillSecondary;
      }
      return color;
    };
    const sentimentComponent = ( sentiment ) => {
      let sentimentComponent;
      let sentimentTag;
      const sentimentText = sentiment && sentiment.toLowerCase();
      const color = sentimentColor(sentiment);
      const tagPresentationStyle = {
        color: color,
        backgroundColor: token.colorBgContainer,
        fontSize: "18px",
        padding: "8px 16px",
        borderRadius: "8px",
      }
      switch (sentimentText){
        case "positive":
          sentimentTag = presentation ? <Tag style={tagPresentationStyle}>Positive</Tag>: <Tag color={sentimentColor(sentimentText)}>POSITIVE</Tag>;
          break;
        case "negative":
          sentimentTag = presentation ? <Tag style={tagPresentationStyle}>Negative</Tag> : <Tag color={sentimentColor(sentimentText)}>NEGATIVE</Tag>;
          break;
        case "neutral":
          sentimentTag = presentation ? <Tag style={tagPresentationStyle}>Neutral</Tag> : <Tag color={sentimentColor(sentimentText)}>NEUTRAL</Tag>;
          break;
        case "mixed":
          sentimentTag = presentation ? <Tag style={tagPresentationStyle}>Mixed</Tag> : <Tag color={sentimentColor(sentimentText)}>MIXED</Tag>;
          break;
        default:
          sentimentTag = "No sentiment detected";
          break;
      }
      if (sentiment && sentiment.toLowerCase().includes("neutral")) {
        sentimentComponent = (
          <>
            <MehOutlined style={{ fontSize: "86px", color: presentation ? "#FFFFFF" : token.colorWarning }} />
            <p style={{ textAlign: "center" }}>
              Overall user sentiment &nbsp;
              {sentimentTag}
            </p>
          </>
        );
      } else if (sentiment && sentiment.toLowerCase().includes("negative")) {
        sentimentComponent = (
          <>
            <FrownOutlined style={{ fontSize: "86px", color: presentation ? "#FFFFFF" : token.colorError }} />
             <p style={{ textAlign: "center" }}>
              Overall user sentiment &nbsp;
              {sentimentTag}
            </p>
          </>
        );
      } else if (sentiment && sentiment.toLowerCase().includes("positive")) {
        sentimentComponent = (
          <>
            <SmileOutlined style={{ fontSize: "86px", color: presentation ? "#FFFFFF" : token.colorSuccess }} />           
            <p style={{ textAlign: "center" }}>
              Overall user sentiment &nbsp;
              {sentimentTag}
            </p>
          </>
        );
      } else if (sentiment && sentiment.toLowerCase().includes("mixed")) {
        sentimentComponent = (
          <>
            <MehOutlined style={{ fontSize: "86px", color: presentation ? "#FFFFFF" : token.colorPrimary }}/>           
            <p style={{ textAlign: "center" }}>
              Overall user sentiment &nbsp;
              {sentimentTag}
            </p>
          </>
         
        );
      } else {
        sentimentComponent = (
          <InfoCircleOutlined
            style={{ fontSize: "86px", color: presentation ? "#FFFFFF" : token.colorFillSecondary }}
          />
        );
      }
      return sentimentComponent;
    };
    
  
    useEffect(() => {
      if(researchData){
        if(researchData.sentiment){
          setSentiment(researchData.sentiment)
          setLoading(false);
        } else if(researchData.summary && researchData.model) {
          setLoading(true);
          getSentiment(summary, researchData.model);
          setLoading(false);
        } else if(!researchData.summary){
          setLoading(true);
        } else{
          setLoading(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary, researchData]);
    
    useEffect(() => {
      if(sentiment){
        setLoading(false);
      } else {
        setLoading(true);
      }
    }, [sentiment])
  
    return (
      
      presentation ? (
        
          <Card bodyStyle={{ padding: "42px 16px", textAlign: "center"}} style={{backgroundColor: sentimentColor(sentiment), color: "#FFFFFF"}}>
              <Row align="center" justify="center">
                <Space direction="vertical" align="center" style={{ width: "100%", fontSize: presentation && "18px"}} justify="center">
                  {sentimentComponent(sentiment)}
                </Space>
              </Row> 
            </Card>
          ) : (
          <Card bodyStyle={{ height: "100%" }} className={styles.sentimentWidget}>
            {contextHolder}
            <Row align="middle" style={{ height: "100%" }} justify="center">
              <Space direction="vertical" align="center" size="small" >
                { 
                  !loading ? (
                    sentiment && (sentimentComponent(sentiment)) ) : (
                    <Loader size="small" loading={loading} title="Analysing sentiment..."/>
                  )
                }
              </Space>
            </Row>
          </Card> ) 
    )
}