import { useContext, createContext, useState, useEffect } from "react";
import {
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    signOut,
    TwitterAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification
} from 'firebase/auth';
import { auth, dbRef, db, onValue } from '../firebase_setup/firebase';
import { message } from "antd";
import { api } from "../utils/";
const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [userData, setUserData] = useState({});
    const [loadingUserData, setLoadingUserData] = useState(true);
    const [userLoaded, setUserLoaded] = useState(false);
    const [userTheme, setUserTheme] = useState("default");
    const [authProvider, setAuthProvider] = useState();
    const [needsVerification, setNeedsVerification] = useState(false);
    const logOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            message.error("Log out failed: " + error.message);
        }
    };    
    const themeChange = ( theme ) => {
        setUserTheme(theme);
    }
    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            setAuthProvider(provider.providerId);
        } catch (error) {
            message.error("Google sign-in error: " + error.message);
        }
    };
    const xSignIn = async () => {
        const provider = new TwitterAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            setAuthProvider(provider.providerId);
        } catch (error) {
            message.error("X sign-in error: " + error.message);
        }
    };
    const emailSignUp = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            // Send verification email
            await sendEmailVerification(user);
            message.success("Account created successfully! Please verify your email.");
    
            // Add user to the database
            
    
            return user;
        } catch (error) {
            message.error("Sign up error: " + error.message);
            throw error;
        }
    };
    
    const emailSignIn = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            if (!user.emailVerified) {
                message.warning("Email not verified. Some features may be unavailable.");
            }
            return user;
        } catch (error) {
            message.error("Login error: " + error.message);
            throw error;
        }
    };
    
        
    const fetchUserData = async (uid) => {
        const userRef = dbRef(db, `/users/${uid}`);
        const unsubscribe = onValue(userRef, async (snapshot) => {
            if (snapshot.exists()) {
                try {
                    const data = await api.get(`/api/getUserData`, { params: { userId: uid } });
                    setUserData(data.data.userData);
                } catch (apiError) {
                    message.error("Failed to fetch user data: " + apiError.message);
                }
            } else {
                setUserData({});
            }
            setLoadingUserData(false);
        }, (error) => {
            console.error("Failed to fetch user data:", error);
            message.error("Failed to load user data.");
            setLoadingUserData(false);
        });
    
        return unsubscribe; // Return the function to unsubscribe from this listener
    };
    
    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            setUserLoaded(true);
            setUser(currentUser);
            if (currentUser) {
                if (!currentUser.emailVerified) {
                    setNeedsVerification(true);
                    setUserData({}); // Clear data for unverified users
                    setLoadingUserData(false);
                    return;
                } else {
                    setNeedsVerification(false);
                }
                setAuthProvider(currentUser.providerData[0]?.providerId);
                setLoadingUserData(true);
                const unsubscribeData = fetchUserData(currentUser.uid);
                return () => unsubscribeData(); // Cleanup data listener when the user changes or on unmount
            } else {
                setUserData({});
                setLoadingUserData(false);
            }
        });
    
        return () => {
            unsubscribeAuth(); // Cleanup auth listener on component unmount
        };
    }, []);    
    
    return (
        <AuthContext.Provider value={{ googleSignIn, xSignIn, user, logOut, userLoaded, themeChange, userTheme, authProvider, userData, loadingUserData, emailSignIn, emailSignUp, needsVerification }}>
            { children }
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}