import React from 'react'
import {
    CSVIcon,
    DOCXIcon,
    PDFIcon,
    MP3Icon,
    MP4Icon,
    PPTXIcon,
    XLSXIcon,
    WAVIcon,
  } from "../../utils";

export function FormatIcon({ extension}) {
        let icon;
        switch (extension) {
          case ".pdf":
            icon = <img className='h-10' src={PDFIcon} alt="PDF file format icon" />;
            break;
          case ".docx":
            icon = <img className='h-10' src={DOCXIcon} alt="DOCX file format icon" />;
            break;
          case ".csv":
            icon = <img className='h-10' src={CSVIcon} alt="CSV file format icon" />;
            break;
          case ".pptx":
            icon = <img className='h-10' src={PPTXIcon} alt="PPTX file format icon" />;
            break;
          case ".xlsx":
            icon = <img className='h-10' src={XLSXIcon} alt="XLSX file format icon" />;
            break;
          case ".mp3":
            icon = <img className='h-10' src={MP3Icon} alt="MP3 file format icon" />;
            break;
          case ".mp4":
            icon = <img className='h-10' src={MP4Icon} alt="MP4 file format icon" />;
            break;
          case ".wav":
            icon = <img className='h-10' src={WAVIcon} alt="WAV file format icon" />;
            break;
          default:
            icon = <img className='h-10' src={PDFIcon} alt="PDF file format icon" />;
        }
  return icon;
}
